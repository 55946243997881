import failedQueuedLogsHandler from "../../services/FailedQueuedLogs";

export default {
    async load({commit, dispatch}, payload) {
        try {
            const {data: {data}} = await failedQueuedLogsHandler.all(payload);

            commit('SET_FAILED_QUEUED_LOGS', data);
        } catch (error) {
            commit('SET_FAILED_QUEUED_LOGS', []);
        }
    },
    async clear({commit, dispatch}, payload) {
        return failedQueuedLogsHandler.clear(payload);
    }
}
