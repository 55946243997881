export default {
  SET_USERS: function (state, payload) {
    state.users = payload
  },
  SET_ADMIN_USERS: function (state, payload) {
    state.adminUsers = payload
  },
  SET_ALL_ADMIN_USERS: function (state, payload) {
    state.allAdminUsers = payload
  },
}
