import sitesStatementHandler from "../../services/SitesStatement";

export default {
    async loadSitesStatement({commit, dispatch}, payload) {
        const {data} = await sitesStatementHandler.all(payload);

        commit('SET_SITES_STATEMENT', data);
    },
    async loadSitesSelling({commit, dispatch}, payload) {
        try {
            const {data: {data}} = await sitesStatementHandler.siteSellingReport(payload);
            commit('SET_SITES_SELLING', data);
        } catch (error) {
            commit('SET_SITES_SELLING', []);
        }
    },

    async downloadSitesStatementAsPDF({commit, dispatch}, payload) {
        return sitesStatementHandler.downloadPDF(payload);
    }
}
