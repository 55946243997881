import Api from './Api';

const all = (payload) => {
    const path = '/wp-user-access';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};


const store = (payload) => {
    return Api.postRequest({
        endpoint: '/wp-user-access/store',
        method: 'PUT',
        data: payload
    });
};

const sync = (payload) => {
    const path = '/wp-user-access/syc-wp-access';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;
    return Api.getRequest({
        endpoint
    });
};

const reset = (payload) => {
    return Api.postRequest({
        endpoint: '/wp-user-access/reset-wp-access',
        method: 'PUT',
        data: payload
    });
};

const skipped = (payload) => {
    return Api.postRequest({
        endpoint: '/wp-user-access/skipped-wp-access',
        method: 'PUT',
        data: payload
    });
};

export default {
    all,
    store,
    sync,
    reset,
    skipped
};
