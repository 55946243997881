import Api from './Api';

const all = (payload) => {
    const path = '/google/indexers';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};

const store = (payload) => {
    return Api.postRequest({
        endpoint: '/google/indexer',
        data: payload
    });
};

const destroy = (payload) => {
    return Api.deleteRequest({
        endpoint: `/google/indexer/destroy/${payload.id}`,
    });
};

export default {
    all,
    store,
    destroy,
};
