import {mapState} from "vuex";
import pTable from "../json/pTable.json"
import _ from "lodash";

export default {
    data() {
      return {
          accessMenu: [
              'site-summary'
          ]
      }
    },
    computed: {
        ...mapState({
            user: state => state.auth.user
        }),
        isAdmin() {
            return this.user && this.user.role_id === 1;
        }
    },
    methods: {
        checkAccess(type, menu = "report") {
            const accessTable = pTable[menu][type];
            return this.user && (this.user.role_id === 1 || _.includes(accessTable, this.user.email));
        }
    }
}