import Vue from 'vue';
import App from './App.vue';
import VueMeta from 'vue-meta';
import VueClipboard from 'vue-clipboard2';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import '@/assets/scss/main.scss';
import VueApexCharts from 'vue-apexcharts';
import VueObserveVisibility from 'vue-observe-visibility';
import VueChatScroll from 'vue-chat-scroll';
import SortableTable from './directive/SortableTable';

Vue.use(VueMeta, {refreshOnceOnNavigation: true});
Vue.use(VueClipboard);
Vue.use(VueApexCharts);
Vue.use(VueObserveVisibility);
Vue.use(VueChatScroll);
Vue.component('apexchart', VueApexCharts);
Vue.directive('sortableTable', SortableTable);
import '@mdi/font/css/materialdesignicons.css';

const token = store.state.auth.token;

Vue.use(require('vue-pusher'), {
  api_key: process.env.VUE_APP_PUSHER_APP_KEY,
  options: {
    cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
    encrypted: false,
    forceTLS: true,
    authEndpoint: `${process.env.VUE_APP_BASE_URL}/api/broadcasting/auth`,
    auth: {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  }
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
