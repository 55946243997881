import ClientSummaryHandler from "../../services/ClientSummary";

export default {
    async load({commit, dispatch}, payload) {
        try {
            const {data: {data: {items}}} = await ClientSummaryHandler.all(payload);

            commit('SET_CLIENT_SUMMARY', items);
        } catch (error) {
            commit('SET_CLIENT_SUMMARY', []);
        }
    }
}
