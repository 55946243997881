import Api from './Api';

const all = (payload) => {
    const path = '/site-logs';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};


const destroy = (payload) => {
    return Api.deleteRequest({
        endpoint: `/site-log/destroy/${payload.id}`,
    });
};

const clear = (payload) => {
    const path = '/site-log/removed-logs';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
}

const allUnreadMessages = (payload) => {
    const path = '/site-log/all-unread-messages';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};

const markedMessageAsRead = (payload) => {
    return Api.postRequest({
        endpoint: '/site-log/marked-messages-as-read',
        method: 'PUT',
        data: payload
    });
};

export default {
    all,
    destroy,
    clear,
    allUnreadMessages,
    markedMessageAsRead
};
