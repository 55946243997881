import extraFacilitiesHandler from "../../services/ExtraFacilities";

export default {
    async load({commit, dispatch}, payload) {
        try {
            const {data: {data}} = await extraFacilitiesHandler.all(payload);

            commit('SET_EXTRA_FACILITIES', data);
        } catch (error) {
            commit('SET_EXTRA_FACILITIES', []);
        }
    },
    async store({commit, dispatch}, payload) {
        return extraFacilitiesHandler.store(payload);
    },
    async update({commit, dispatch}, payload) {
        return extraFacilitiesHandler.update(payload);
    },
    async remove({commit, dispatch}, payload) {
        return extraFacilitiesHandler.remove(payload);
    },
    async destroy({commit, dispatch}, payload) {
        return extraFacilitiesHandler.destroy(payload);
    },
}
