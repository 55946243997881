import wpAccessHandler from "../../services/WPAccess";

export default {
    async load({commit, dispatch}, payload) {
        try {
            const {data: {data}} = await wpAccessHandler.all(payload);

            commit('SET_WP_SITES', data);
        } catch (error){
            commit('SET_WP_SITES', []);
        }
    },
    async update({commit, dispatch}, payload) {
        return wpAccessHandler.store(payload);
    },
    async sync({commit, dispatch}, payload) {
        return wpAccessHandler.sync(payload);
    },
    async reset({commit, dispatch}, payload) {
        return wpAccessHandler.reset(payload);
    },
    async skipped({commit, dispatch}, payload) {
        return wpAccessHandler.skipped(payload);
    }
}
