import siteOwnershipHandler from "../../services/SiteOwnership";

export default {
    async load({commit, dispatch}, payload) {
        try {
            const {data: {data}} = await siteOwnershipHandler.all(payload);

            commit('SET_SITE_OWNERSHIPS', data);
        } catch (error){
            commit('SET_SITE_OWNERSHIPS', []);
        }
    },
    async update({commit, dispatch}, payload) {
        return siteOwnershipHandler.update(payload);
    }
}
