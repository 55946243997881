import orderReplicaHandler from "../../services/OrderReplica";

export default {
    async load({commit, dispatch}, payload) {
        try {
            const {data : {data}} = await orderReplicaHandler.all(payload);
            commit('SET_ORDER_REPLICA', data);
        } catch (error) {
            commit('SET_ORDER_REPLICA', []);
        }
    },
    async get({commit, dispatch}, payload) {
        return orderReplicaHandler.get(payload);
    },
    async destroy({commit, dispatch}, payload) {
        return orderReplicaHandler.destroy(payload);
    },
}
