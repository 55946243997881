import siteLogsHandler from "../../services/SiteLogs";

export default {
    async load({commit, dispatch}, payload) {
        try {
            const {data: {data}} = await siteLogsHandler.all(payload);

            commit('SET_SITE_LOGS', data);
        } catch (error) {
            commit('SET_SITE_LOGS', []);
        }
    },
    async destroy({commit, dispatch}, payload) {
        return siteLogsHandler.destroy(payload);
    },
    async clear({commit, dispatch}, payload) {
        return siteLogsHandler.clear(payload);
    },
    async markedMessageAsRead({commit, dispatch}, payload) {
        return siteLogsHandler.markedMessageAsRead(payload);
    }
}
