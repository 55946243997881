import user from "../../services/User";

export default {
    async loadUsers({commit, dispatch}, payload) {
        const {data} = await user.getUsers(payload);
        if (data && data.data) {
            commit("SET_USERS", data.data);
        }
    },
    async loadAdminUsers({commit, dispatch}, payload) {
        const {data} = await user.getAdmins(payload);
        if (data && data.data) {
            commit("SET_ADMIN_USERS", data.data);
        }
    },
    async loadAllAdminUsers({commit, dispatch}, payload) {
        const {data} = await user.getAllAdmins(payload);
        if (data && data.data) {
            commit("SET_ALL_ADMIN_USERS", data.data);
        }
    },
    async register({commit, dispatch}, payload) {
        return user.register(payload);
    },
    async updateAdmin({commit, dispatch}, payload) {
        return user.update(payload);
    },
    async updateStatus({commit, dispatch}, payload) {
        return user.updateStatus(payload);
    },
    async updateProfile({}, payload) {
        return user.updateProfile(payload);
    },
    async updatePassword({}, payload) {
        return user.updatePassword(payload);
    },
    async updateEmail({}, payload) {
        return user.updateEmail(payload);
    },
    async remove({commit, dispatch}, payload) {
        return user.remove(payload);
    },
    async destroy({commit, dispatch}, payload) {
        return user.destroy(payload);
    },
    async restore({commit, dispatch}, payload) {
        return user.restore(payload);
    },
    async resetAdminPassword({commit, dispatch}, payload) {
        return user.resetAdminPassword(payload)
    }
}
