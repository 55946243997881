import axios from "axios";
import store from "@/store";
import router from "@/router";

let api = process.env.VUE_APP_API_BASE_URL;

let Api = axios.create({
    baseURL: api,
})

Api.defaults.withCredentials = true;

Api.interceptors.response.use((response) => {
    return response;
}, (error) => {
    // check if it's a server error
    if (!error.response) {
        //notify.warn('Network/Server error');
        console.log('**Network/Server error**');
        return Promise.reject(error);
    }

    // all the other error responses
    switch (error.response.status) {
        case 400:
            console.log(error.response.status, error.message);
            //notify.warn('Nothing to display', 'Data Not Found');
            break;

        case 401: // authentication error, logout the user
            //notify.warn('Please login again', 'Session Expired');
            console.log(error.response.status, error.message);
            store.commit("auth/SET_TOKEN", null);
            store.commit("auth/SET_AUTHENTICATED", false);
            store.commit("auth/SET_USER", null);
            router.push("/login");
            break;

        default:
            console.log(error.response.status, error.message);
            //notify.error('Server Error');
    }

    return Promise.reject(error);
});

const getAuthorizationHeader = () => {
    const token = store.state.auth.token;
    const header = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
    };
    if (token && token) {
        header['Authorization'] = `Bearer ${token}`;
    }
    return header;
};

const getAuthHeaderForFormData = () => {
    const token = store.state.auth.token;
    const header = {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "multipart/form-data",
    };
    if (token && token) {
        header["Authorization"] = `Bearer ${token}`;
    }
    return header;
};

const getRequest = async function (payload) {
    return Api({
        url: payload.endpoint,
        method: 'GET',
        headers: getAuthorizationHeader(),
        config: {responseType: 'blob'}
    });
};

const postRequest = async function (payload) {
    let data = payload.data;
    let headers = getAuthorizationHeader();
    if(data.mailCsv) {
        headers['Content-Type'] = 'multipart/form-data';
        let formData = new FormData();
        formData.append('mailCsv', data.mailCsv);
        formData.append('mailType', data.mailType);
        data = formData;
    }

    if(data.tempImage) {
        headers['Content-Type'] = 'multipart/form-data';
        let formData = new FormData();
        formData.append('tempImage', data.tempImage);
        formData.append('tempName', data.tempName);
        data = formData;
    }

    return Api({
        url: payload.endpoint,
        method: payload.method || 'POST',
        headers: getAuthorizationHeader(),
        data: data
    });
};

const deleteRequest = async function (payload) {
    return Api({
        url: payload.endpoint,
        method: 'DELETE',
        headers: getAuthorizationHeader()
    });
};

const postWithFormData = async function (payload) {
    let data = new FormData();
    // eslint-disable-next-line no-debugger
    Object.keys(payload.data).forEach((key) => {
        data.append(key, payload.data[key]);
    });
    return Api({
        url: payload.endpoint,
        method: payload.method || "POST",
        headers: getAuthHeaderForFormData(),
        data: data,
    });
};

export default {
    getRequest,
    postRequest,
    deleteRequest,
    postWithFormData
};
