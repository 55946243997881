import salesCountersHandler from "../../services/SalesCounter";

export default {
    async load({commit, dispatch}, payload) {
        try {
            const {data: {data: {items}}} = await salesCountersHandler.all(payload);
            commit('SET_SALES_COUNTERS', items);
        } catch (error) {
            commit('SET_SALES_COUNTERS', []);
        }
    },
    async get({commit, dispatch}, payload) {
        return salesCountersHandler.get(payload);
    },
    async store({commit, dispatch}, payload) {
        return salesCountersHandler.store(payload);
    },
    async update({commit, dispatch}, payload) {
        return salesCountersHandler.update(payload);
    },
    async remove({commit, dispatch}, payload) {
        return salesCountersHandler.remove(payload);
    },
    async destroy({commit, dispatch}, payload) {
        return salesCountersHandler.destroy(payload);
    },
    async restore({commit, dispatch}, payload) {
        return salesCountersHandler.restore(payload);
    },
    async republish({commit, dispatch}, payload) {
        return salesCountersHandler.republish(payload);
    },
    async counterDetails({commit, dispatch}, payload) {
        return salesCountersHandler.counterDetails(payload);
    },
    async loadSalesCounterEmail({commit, dispatch}, payload) {
        try {
            const {data: {data}} = await salesCountersHandler.getAllEmail(payload);
            commit('SET_SALES_COUNTER_EMAIL', data);
        } catch (error){
            commit('SET_SALES_COUNTER_EMAIL', []);
        }
    },
    async loadSitesReports({commit, dispatch}, payload) {
        try {
            const {data: {data: {items}}} = await salesCountersHandler.salesReportBySite(payload);
            commit('SET_SALES_COUNTERS', items);
        } catch (error) {
            commit('SET_SALES_COUNTERS', []);
        }
    },
    async loadSiteSummaryReports({commit, dispatch}, payload) {
        try {
            const {data: {data: {items}}} = await salesCountersHandler.siteSummaryReports(payload);
            commit('SET_SITE_SUMMARY', items);
        } catch (error) {
            commit('SET_SITE_SUMMARY', []);
        }
    },
    async removePosts({commit, dispatch}, payload) {
        return salesCountersHandler.postRemove(payload);
    },
    async changedPrepaymentStatus({commit, dispatch}, payload) {
        return salesCountersHandler.prepaymentStatus(payload);
    },
    destroyWithoutPost({commit, dispatch}, payload) {
        return salesCountersHandler.deleteWithoutPost(payload);
    },
    async republishMarkAsRead({commit, dispatch}, payload) {
        return salesCountersHandler.republishMarkAsRead(payload);
    },
    async removeBulkPostItem({commit, dispatch}, payload) {
        return salesCountersHandler.removeBulkPosts(payload);
    },
    async deleteBulkPostItem({commit, dispatch}, payload) {
        return salesCountersHandler.deleteBulkPosts(payload);
    },
    async restoreBulkPostItem({commit, dispatch}, payload) {
        return salesCountersHandler.restoreBulkPosts(payload);
    },
}
