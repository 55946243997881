import siteOwnerHandler from "../../services/SiteOwner";

export default {
    async load({commit, dispatch}, payload) {
        try {
            const {data: {data}} = await siteOwnerHandler.all(payload);

            commit('SET_SITE_OWNERS', data);
        } catch (error) {
            commit('SET_SITE_OWNERS', []);
        }
    },
    async store({commit, dispatch}, payload) {
        return siteOwnerHandler.store(payload);
    },
    async update({commit, dispatch}, payload) {
        return siteOwnerHandler.update(payload);
    },
    async remove({commit, dispatch}, payload) {
        return siteOwnerHandler.remove(payload);
    },
    async destroy({commit, dispatch}, payload) {
        return siteOwnerHandler.destroy(payload);
    },
    async restore({commit, dispatch}, payload) {
        return siteOwnerHandler.restore(payload);
    },
}
