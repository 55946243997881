import Api from './Api';

const all = (payload) => {
    const path = '/replica-orders';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};
const get = (payload) => {
    return Api.getRequest({
        endpoint:`/replica-order/get/${payload.salesCounterId}`,
    });
};

const destroy = (payload) => {
    return Api.deleteRequest({
        endpoint: `/replica-order/destroy/${payload.id}`,
    });
};

export default {
    all,
    get,
    destroy,
};
