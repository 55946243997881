import Api from './Api';

const all = (payload) => {
    const path = '/broken-links';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};


const checkBrokenLink = (payload) => {
    return Api.postRequest({
        endpoint: '/check-broken-link',
        data: payload
    });
};

const update = (payload) => {
    return Api.postRequest({
        endpoint: '/broken-link/update',
        method: 'PUT',
        data: payload
    });
};

const destroy = (payload) => {
    return Api.deleteRequest({
        endpoint: `/broken-link/destroy/${payload.id}`,
    });
};

const clearBrokenLink = (payload) => {
    const path = '/broken-link/clear-link';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
}

const getAllUnreadBrokenLink = (payload) => {
    const path = '/broken-link/get-all-unread-messages';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};

const markAsRead = (payload) => {
    return Api.postRequest({
        endpoint: '/broken-link/mark-as-read',
        method: 'PUT',
        data: payload
    });
};

export default {
    all,
    checkBrokenLink,
    update,
    destroy,
    clearBrokenLink,
    getAllUnreadBrokenLink,
    markAsRead
};
