<template>
<base-notification
    :messages="messages"
    title="Sites Logs"
    @markedMessageAsRead="markedMessageAsRead"
    icon="mdi-alpha-e-circle-outline"
>
  <v-list two-line>
    <v-list-item-group
        v-model="selected"
        active-class="pink--text"
        return-object
        @change="markedMessageAsRead($event)"
    >
      <template v-for="(item, index) in items">
        <v-list-item>
          <template v-slot:default="{ active }">
            <v-list-item-content>
              <v-list-item-subtitle>{{item.logs}}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-list-item-action-text v-text="getTimeDiff(item.created_at)"></v-list-item-action-text>
              <v-icon
                  v-if="!active"
                  color="grey lighten-1"
              >
                mdi-star-outline
              </v-icon>

              <v-icon
                  v-else
                  color="yellow darken-3"
              >
                mdi-star
              </v-icon>
            </v-list-item-action>
          </template>
        </v-list-item>

        <v-divider
            v-if="index < items.length - 1"
            :key="index"
        ></v-divider>
      </template>
    </v-list-item-group>
    <div
        v-if="!isLastPage"
        v-observe-visibility="{callback: visibilityChanged}"
        class="d-flex justify-center mb-6"
    >
      <v-progress-circular
          indeterminate
          color="primary"
      />
    </div>
  </v-list>
</base-notification>
</template>

<script>
import BaseNotification from "./BaseNotification";
import NotificationMixin from "../mixins/NotificationMixin";
export default {
  name: "SiteLogsNotifications",
  components: {BaseNotification},
  mixins: [NotificationMixin],
  data() {
    return {
      actions: {
        update: "siteLog/markedMessageAsRead"
      },
      broadcastChannel:  "error-logs-notification-event",
      notificationChannel: "error-logs-channel",
      apiPath: "site-log/all-unread-messages",
      descending: true,
      sortBy: "id"
    }
  }
}
</script>

<style scoped>

</style>