export default {
    authenticated(state) {
        return state.authenticated;
    },
    user(state) {
        const { name, email } = state.auth.user;
        return { name, email };
    },
    token(state) {
        return state.token;
    },
}