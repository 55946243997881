import queuedJobLogsHandler from "../../services/QueuedJobLogs";

export default {
    async load({commit, dispatch}, payload) {
        try {
            const {data: {data}} = await queuedJobLogsHandler.all(payload);

            commit('SET_QUEUED_JOB_LOGS', data);
        } catch (error) {
            commit('SET_QUEUED_JOB_LOGS', []);
        }
    }
}
