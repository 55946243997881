import mailTemplateHandler from "../../services/MailTemplate";

export default {
    async load({commit, dispatch}, payload) {
        try {
            const {data: {data}} = await mailTemplateHandler.all(payload);

            commit('SET_MAIL_TEMPLATE', data);
        } catch (error) {
            commit('SET_MAIL_TEMPLATE', []);
        }
    },
    async update({commit, dispatch}, payload) {
        return mailTemplateHandler.update(payload);
    },
    async getByType({commit, dispatch}, payload) {
        return mailTemplateHandler.get(payload);
    },
    async sendInvoiceEmail({commit, dispatch}, payload) {
        return mailTemplateHandler.sendInvoiceEmail(payload);
    },
}
