import newsletterLogsHandler from "../../services/NewsletterLogs";

export default {
    async load({commit, dispatch}, payload) {
        try {
            const {data: {data}} = await newsletterLogsHandler.all(payload);

            commit('SET_NEWSLETTER_LOGS', data);
        } catch (error) {
            commit('SET_NEWSLETTER_LOGS', []);
        }
    },
    async clear({commit, dispatch}, payload) {
        return newsletterLogsHandler.clear(payload);
    }
}
