import statementHandler from "../../services/Statement";

export default {
    async load({commit, dispatch}, payload) {
        try {
            const {data:{data}} = await statementHandler.all(payload);
            commit('SET_STATEMENTS', data);
        } catch (error) {
            commit('SET_STATEMENTS', []);
        }
    },

    async downloadAsPDF({commit, dispatch}, payload) {
        return statementHandler.downloadPDF(payload);
    }
}
