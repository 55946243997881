<template>
  <transition name="slide-fade">
    <div>
      <v-navigation-drawer
          v-model="drawer"
          :class="baseColor"
          :clipped="clipped"
          :mini-variant="miniVariant"
          app
          dark
          fixed
          mini-variant-width="100"
      >
        <app-sidebar :mini-variant="miniVariant"/>
      </v-navigation-drawer>
      <v-app-bar
          :class="baseColor"
          :clipped-left="clipped"
          dark
          dense
          elevation="1"
      >
        <v-app-bar-nav-icon @click.stop="miniVariant = !miniVariant"></v-app-bar-nav-icon>
        <v-btn
            icon
            @click.stop="drawer = !drawer"
            style="margin: 6px 0px"
        >
          <v-icon>{{ `chevron_${drawer ? 'right' : 'left'}` }}</v-icon>
        </v-btn>

        <v-chip
            v-if="show"
            class="mr-2"
            color="primary"
            link
            to="/sale-counters"
        >
          Reseller Order List
        </v-chip>
        <v-chip
            v-if="show"
            class="mr-2"
            color="pink"
            link
            to="/sale-counter"
        >
          Create a New Order
        </v-chip>
        <v-chip
            v-if="show"
            class="mr-2"
            color="success"
            link
            to="/invoice-dashboard"
        >
          Reseller Invoice List
        </v-chip>
        <v-chip
            v-if="show"
            class="mr-2"
            color="#d243e7"
            link
            to="/invoice"
        >
          Create a New Invoice
        </v-chip>
        <v-spacer/>
        <duplicated-links-logs-notifications v-if="user && (user.email !== 'ceo@rabbiitfirm.com')"/>
        <div class="mx-2"/>
        <republish-notification v-if="user && (user.email !== 'ceo@rabbiitfirm.com')"/>
        <div class="mx-2"/>
        <site-logs-notifications v-if="user && (user.email !== 'ceo@rabbiitfirm.com')"/>
        <div class="mx-2"/>
        <broken-link-notifications v-if="user && (user.email !== 'ceo@rabbiitfirm.com')"/>
        <div class="mx-2"/>
        <invoice-notification v-if="user && (user.role_id === 1 || user.email === 'manirhossain369@gmail.com' || user.email === 'rezaulbdbh@gmail.com') && user.email !== 'ceo@rabbiitfirm.com'"/>
        <div class="mx-2"/>
        <user-menu/>
      </v-app-bar>
    </div>
  </transition>
</template>

<script>
import AppSidebar from "../components/menu/AppSidebar";
import UserMenu from "../components/user/UserMenu";
import InvoiceNotification from "../components/notification/InvoiceNotification";
import UserMixin from "../components/mixins/UserMixin";
import BrokenLinkNotifications from "../components/notification/BrokenLinkNotifications";
import SiteLogsNotifications from "../components/notification/SiteLogsNotifications";
import RepublishNotification from "../components/notification/RepublishNotification";
import DuplicatedLinksLogsNotifications from "../components/notification/DuplicatedLinkNotifications";

export default {
  name: "AppHeader",
  components: {
    DuplicatedLinksLogsNotifications,
    RepublishNotification,
    SiteLogsNotifications,
    BrokenLinkNotifications,
    InvoiceNotification,
    UserMenu,
    AppSidebar
  },
  mixins: [UserMixin],
  data() {
    return {
      baseColor: 'deep-purple accent-4',
      clipped: true,
      drawer: false,
      fixed: false,
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: 'ShopRoller',
      icon: 'shopping_cart'
    }
  },
  computed: {
    show () {
      switch (this.$vuetify.breakpoint.name) {
        case 'sm': return false
        case 'xs': return false
        default: return true
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.v-navigation-drawer--mini-variant .v-list-item > *:nth-child(3) {
  position: relative !important;
  height: 24px !important;
  width: 24px !important;
}

.v-navigation-drawer--mini-variant .v-list-item > :first-child {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.v-navigation-drawer--mini-variant .v-list-group__header {
  padding-right: 0 !important;
}
</style>
