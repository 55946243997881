<template>
  <v-menu
      :close-on-content-click="menu"
      min-width="250"
      offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-chip
          v-show="showUserName"
          v-on="on"
          color="primary"
      >
        <v-icon left small>mdi-account-circle</v-icon>
        {{ userName }}
      </v-chip>
      <v-btn
          v-show="!showUserName" v-on="on" color="primary"
          dark
          fab
          x-small
      >
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item link @click.prevent.stop="routeHandler('Profile')">
        <v-list-item-icon>
          <v-icon>mdi-account</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Profile</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider/>
      <v-list-item v-if="isAdmin" link @click.prevent.stop="routeHandler('ChangeAdminPassword')">
        <v-list-item-icon>
          <v-icon>mdi-lock</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Change Admin Password</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider/>
      <template v-if="isAdmin">
        <v-list-item link @click.prevent.stop="routeHandler('Reports')">
          <v-list-item-icon>
            <v-icon>mdi-equal-box</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Reports</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider/>
      </template>
      <template v-if="isAdmin || checkAccess('googleSheetSync', 'userMenu')">
        <v-list-item link @click.prevent.stop="routeHandler('GoogleSheetSync')">
          <v-list-item-icon>
            <v-icon>mdi-google-spreadsheet</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> GoogleSheet Sync</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider/>
      </template>
      <template v-if="isAdmin">
        <v-list-item link @click.prevent.stop="routeHandler('SystemsSync')">
          <v-list-item-icon>
            <v-icon>mdi-sync</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Systems Sync</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider/>
      </template>
      <template v-if="isAdmin">
        <v-list-item link @click.prevent.stop="routeHandler('RestoreRestrictedOrders')">
          <v-list-item-icon>
            <v-icon>mdi-backup-restore</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Restore Orders</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider/>
      </template>
      <v-list-item link @click.prevent.stop="logoutHandler">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {mapState} from 'vuex';
import User from "../../services/User";
import IsAuthenticated from "../../middleware/IsAuthenticated";

export default {
  name: "UserMenu",
  mixins: [IsAuthenticated],
  data() {
    return {
      showBadge: false,
      menu: false
    }
  },
  mounted() {
    User.auth().then(response => {
      this.$store.commit("auth/SET_USER", response.data)
    }).catch(error => {
      console.log(error);
    })
  },
  computed: {
    ...mapState({
      userName: function (state) {
        return state.auth.user && state.auth.user.name ? state.auth.user.name : ''
      }
    }),
    showUserName() {
      return this.$vuetify.breakpoint.mdAndUp;
    }
  },
  methods: {
    logoutHandler() {
      User.logout()
          .then(() => {
            this.removeAuthentication();
            this.$router.push({name: 'Login'});
          })
          .catch((error) => {
            this.removeAuthentication();
            this.$router.push({name: 'Login'});
          })
    },
    removeAuthentication() {
      this.$store.commit("auth/SET_TOKEN", null);
      this.$store.commit("auth/SET_AUTHENTICATED", false);
      this.$store.commit("auth/SET_USER", null);
    },
    routeHandler(name) {
      this.$router.push({name: name});
    }
  }
}
</script>

<style scoped>

</style>
