import indexerHandler from "../../services/Indexer";
import methodHandler from "../../services/Methods";

export default {
    async load({commit, dispatch}, payload) {
        try {
            let {data: {data}} = await indexerHandler.all(payload);
            commit('SET_INDEXERS', data);
        } catch (error) {
            commit('SET_INDEXERS', []);
        }
    },
    async store({commit, dispatch}, payload) {
        return indexerHandler.store(payload);
    },
    async destroy({commit, dispatch}, payload) {
        return indexerHandler.destroy(payload);
    },
}
