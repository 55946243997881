<template>
  <v-app>
    <v-main>
      <router-view name="header"/>
      <cache-component />

      <router-view/>

      <router-view name="footer"/>
      <snackbar/>
    </v-main>
  </v-app>
</template>

<script>
import Snackbar from "./components/Snackbar";
import CacheComponent from "./components/CacheComponent";
export default {
  name: 'App',
  components: {CacheComponent, Snackbar},
  metaInfo: {
    title: 'rabbiitfirm.com',
    titleTemplate: 'Admin Dashboard | %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
};
</script>

<style lang="scss">
/*For Input Field Right Align*/
.right-align-text input {
  text-align: right;
}
</style>
