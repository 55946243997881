<template>
  <v-chip
      :class="cssClass"
      :color="color"
      :text-color="textColor"
      :width="width"
      :outlined="outlined"
  >
    {{text}}
  </v-chip>
</template>

<script>
export default {
  name: "GPaymentStatusChip",
  props: {
    cssClass: {
      type: String,
      default: () => ('ma-2')
    },
    color: {
      type: String,
      default: () => ('red')
    },
    textColor: {
      type: String,
      default: () => ('white')
    },
    text: {
      type: String,
      default: () => ('Unpaid')
    },
    width: {
      type: Number,
      default: () => 200
    },
    outlined: {
      type: Boolean,
      default: () => false
    }
  }
}
</script>

<style scoped>

</style>