import Api from './Api';

const all = (payload) => {
    const path = '/link-insertions';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};

const get = (payload) => {
    return Api.postRequest({
        endpoint: '/link-insertion/get',
        data: payload
    });
}

const update = (payload) => {
    return Api.postRequest({
        endpoint: '/link-insertion/update',
        method: 'PUT',
        data: payload
    });
};

export default {
    all,
    get,
    update
};
