import BrokenLinkHandler from "../../services/BrokenLinks";

export default {
    async load({commit, dispatch}, payload) {
        try {
            const {data: {data}} = await BrokenLinkHandler.all(payload);
            commit('SET_BROKEN_LINKS', data);
        } catch (error) {
            commit('SET_BROKEN_LINKS', []);
        }
    },
    async check({commit, dispatch}, payload) {
        return BrokenLinkHandler.checkBrokenLink(payload);
    },
    async update({commit, dispatch}, payload) {
        return BrokenLinkHandler.update(payload);
    },
    async destroy({commit, dispatch}, payload) {
        return BrokenLinkHandler.destroy(payload);
    },
    async clear({commit, dispatch}, payload) {
        return BrokenLinkHandler.clearBrokenLink(payload);
    },
    async markAsRead({commit, dispatch}, payload) {
        return BrokenLinkHandler.markAsRead(payload);
    }
}
