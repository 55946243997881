import linkInsertionHandler from "../../services/LinkInsertion";

export default {
    async load({commit, dispatch}, payload) {
        try {
            const {data: {data}} = await linkInsertionHandler.all(payload);

            commit('SET_LINK_INSERTION', data);
        } catch (error) {
            commit('SET_LINK_INSERTION', []);
        }
    },
    async get({commit, dispatch}, payload) {
        return linkInsertionHandler.get(payload);
    },
    async update({commit, dispatch}, payload) {
        return linkInsertionHandler.update(payload);
    }
}
