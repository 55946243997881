import invoiceHandler from "../../services/Invoice";

export default {
    async load({commit, dispatch}, payload) {
        try {
            const {data: {data: {items}}} = await invoiceHandler.all(payload);
            commit('SET_INVOICES', items);
        } catch (error){
            commit('SET_INVOICES', []);
        }
    },
    async store({commit, dispatch}, payload) {
        return invoiceHandler.store(payload);
    },
    async get({commit, dispatch}, payload) {
        return invoiceHandler.get(payload);
    },
    async getWithMethod({commit, dispatch}, payload) {
        return invoiceHandler.getWithMethod(payload);
    },
    async makeManualPayment({commit, dispatch}, payload) {
        return invoiceHandler.updateManualPayment(payload);
    },
    async update({commit, dispatch}, payload) {
        return invoiceHandler.update(payload);
    },
    async remove({commit, dispatch}, payload) {
        return invoiceHandler.remove(payload);
    },
    async destroy({commit, dispatch}, payload) {
        return invoiceHandler.destroy(payload);
    },
    async restore({commit, dispatch}, payload) {
        return invoiceHandler.restore(payload);
    },
    async reset({commit, dispatch}, payload) {
        return invoiceHandler.resetInvoice(payload);
    },
    async resendMailHandler({commit, dispatch}, payload) {
        return invoiceHandler.paymentMail(payload);
    },
    async sendReminderMail({commit, dispatch}, payload) {
        return invoiceHandler.reminderMail(payload);
    },
    async sendAfterReminderMail({commit, dispatch}, payload) {
        return invoiceHandler.afterReminderMail(payload);
    },
    async refundInvoice({commit, dispatch}, payload) {
        return invoiceHandler.refund(payload);
    },
    async markedAsRead({commit, dispatch}, payload) {
        return invoiceHandler.invoiceMarkAsRead(payload);
    },
    async getDescriptionByEmail({commit, dispatch}, payload) {
        return invoiceHandler.getInvoiceDescriptionByEmail(payload);
    },
    async republishedEmail({commit, dispatch}, payload) {
        return invoiceHandler.republishedEmail(payload);
    }
}
