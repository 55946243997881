import methodHandler from "../../services/Methods";

export default {
    async load({commit, dispatch}, payload) {
        try {
            const {data:{data}} = await methodHandler.all(payload);

            commit('SET_METHODS', data);
        } catch (error){
            commit('SET_METHODS', []);
        }
    },
    async all({commit, dispatch}, payload) {
        try {
            const {data:{data}} = await methodHandler.getAll(payload);
            commit('SET_ALL_PAY_METHODS', data.items);
        } catch (error){
            commit('SET_ALL_PAY_METHODS', []);
        }
    },
    async store({commit, dispatch}, payload) {
        return methodHandler.store(payload);
    },
    async update({commit, dispatch}, payload) {
        return methodHandler.update(payload);
    },
    async remove({commit, dispatch}, payload) {
        return methodHandler.remove(payload);
    },
    async destroy({commit, dispatch}, payload) {
        return methodHandler.destroy(payload);
    },
    async bulkUpdate({commit, dispatch}, payload) {
        return methodHandler.bulkUpdate(payload);
    }
}
