import deliveryEmailLogsHandler from "../../services/DeliveryEmailLogs";

export default {
    async load({commit, dispatch}, payload) {
        try {
            const {data: {data}} = await deliveryEmailLogsHandler.all(payload);

            commit('SET_DELIVERY_EMAIL_LOGS', data);
        } catch (error) {
            commit('SET_DELIVERY_EMAIL_LOGS', []);
        }
    },
    async clear({commit, dispatch}, payload) {
        return deliveryEmailLogsHandler.clear(payload);
    }
}
