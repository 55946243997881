import Api from './Api';

const dSummary = (payload) => {
    const path = '/dashboard-summary';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};

const invoiceByDate = (payload) => {
    const path = '/invoice-by-date';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};


const rabbiITPayments = (payload) => {
    const path = '/payment/reports/rabbi-it';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};

const shantoITPayments = (payload) => {
    const path = '/payment/reports/shanto-it';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};

const randomPayments = (payload) => {
    const path = '/payment/reports/random';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};


const downloadRabbiITPDF = (payload) => {
    const path = '/payment/reports/rabbi-it/pdf';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};

export default {
    dSummary,
    invoiceByDate,
    rabbiITPayments,
    shantoITPayments,
    randomPayments,
    downloadRabbiITPDF
};
