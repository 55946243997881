import Api from "./Api";

const getUsers = (payload) => {
    const path = '/get-users';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};

const getAdmins = (payload) => {
    const path = '/get-admins';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};

const admins = (payload) => {
    const path = '/admins';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};

const getAllAdmins = (payload) => {
    const path = '/get-all-admins';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};

const register = async (data) => {
    return Api.postRequest({
        endpoint: '/register',
        data: data
    })
}

const login = async (data) => {
    return Api.postRequest({
        endpoint: '/admin-login',
        data: data
    })
}

const googleLogin = async (data) => {
    return Api.postRequest({
        endpoint: '/google-login',
        data: data
    })
}

const logout = async () => {
    return Api.postRequest({
        endpoint: '/logout',
    })
}

const auth = async () => {
    return Api.getRequest({endpoint: '/user'});
}

const updateStatus = async (data) => {
    return Api.postRequest({
        endpoint: '/update-user-status',
        method: 'PUT',
        data: data
    })
}

const update = async (data) => {
    return Api.postRequest({
        endpoint: '/update-admin',
        method: 'PUT',
        data: data
    })
}

const updateProfile = async (data) => {
    return Api.postRequest({
        endpoint: '/update-profile',
        method: 'PUT',
        data: data
    })
}

const updatePassword = async (data) => {
    return Api.postRequest({
        endpoint: '/update-password',
        method: 'PUT',
        data: data
    })
}

const updateEmail = async (data) => {
    return Api.postRequest({
        endpoint: '/update-email',
        method: 'PUT',
        data: data
    })
}

const remove = (payload) => {
    return Api.deleteRequest({
        endpoint: `/user/delete/${payload.id}`,
    });
};

const destroy = (payload) => {
    return Api.deleteRequest({
        endpoint: `/user/destroy/${payload.id}`,
    });
};

const verify = async (payload) => {
    const code = payload.code;
    return Api.getRequest({endpoint: `/verify/${code}`});
}

const forgotPassword = async (data) => {
    return Api.postRequest({
        endpoint: '/forgot-password',
        data: data
    })
}

const resetPassword = async (payload) => {
    const path = '/reset-password';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;
    return Api.getRequest({
        endpoint
    })
}

const saveForgotPassword = async (data) => {
    return Api.postRequest({
        endpoint: '/save-forgot-password',
        data: data
    })
}

const resetAdminPassword = async (data) => {
    return Api.postRequest({
        endpoint: '/reset-admin-password',
        method: 'PUT',
        data: data
    })
};

const restore = (payload) => {
    return Api.getRequest({
        endpoint: `/user/restore/${payload.id}`,
    });
};

const csrf = async () => {
    return Api.getRequest({ endpoint: "/csrf-cookie" });
};

export default {
    login,
    logout,
    auth,
    update,
    register,
    googleLogin,
    getUsers,
    getAdmins,
    updateStatus,
    updateProfile,
    updatePassword,
    updateEmail,
    remove,
    destroy,
    getAllAdmins,
    verify,
    forgotPassword,
    resetPassword,
    saveForgotPassword,
    resetAdminPassword,
    restore,
    csrf
};
