import ShantoITInvoiceHandler from "../../services/ShantoITInvoice";

export default {
    async load({commit, dispatch}, payload) {
        try {
            const {data:{data}} = await ShantoITInvoiceHandler.all(payload);

            commit('SET_SHANTO_IT_INVOICE', data);
        } catch (error) {
            commit('SET_SHANTO_IT_INVOICE', []);
        }
    }
}
