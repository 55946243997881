import nicheCategoriesHandler from "../../services/NicheCategories";

export default {
    async load({commit, dispatch}, payload) {
        try {
            const {data: {data}} = await nicheCategoriesHandler.all(payload);

            commit('SET_NICHE_CATEGORIES', data);
        } catch (error) {
            commit('SET_NICHE_CATEGORIES', []);
        }

    },
    async loadByStatus({commit, dispatch}, payload) {
        try {
            const {data: {data}} = await nicheCategoriesHandler.getByStatus(payload);

            commit('SET_NICHE_CATEGORIES', data);
        } catch (error) {
            commit('SET_NICHE_CATEGORIES', []);
        }
    },
    async store({commit, dispatch}, payload) {
        return nicheCategoriesHandler.store(payload);
    },
    async update({commit, dispatch}, payload) {
        return nicheCategoriesHandler.update(payload);
    },
    async restore({commit, dispatch}, payload) {
        return nicheCategoriesHandler.restore(payload);
    },
    async remove({commit, dispatch}, payload) {
        return nicheCategoriesHandler.remove(payload);
    },
    async destroy({commit, dispatch}, payload) {
        return nicheCategoriesHandler.destroy(payload);
    },
}
